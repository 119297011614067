.fake-span {
  height: 35px;
  display: block;
  border-radius: 4px;
  background: linear-gradient(to right, #ebebeb, #d2d2d2);
  background-size: 200% 100%;
  animation: colorMove 1s linear infinite alternate;
}

@keyframes colorMove {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 0%;
  }
}
