.o-radio {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 12px 18px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  transition: 0.2s;
  transition-property: background, color;
  color: #2b3e5b;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 25px;
  white-space: nowrap;
  &.group {
    border-radius: 0;
    $border-radius: 5px;
    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
    input {
      display: none;
    }
  }

  &.disabled {
    color: #000;
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:not(.disabled):hover,
  &.active {
    background: #2b3e5b;
    border-color: #2b3e5b;
    color: #fff;

    &.disabled {
      background: #a29791;
    }

    input {
      &::before {
        transform: scale(1.5);
        outline: 2px solid #fff;
        outline-offset: 1px;
      }
    }
  }

  input {
    margin: 0;
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0.15em solid #2b3e5b;
    border-radius: 50%;
    display: grid;
    place-content: center;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      background-color: #fff;
    }
  }
}
